import {REQUEST_TYPE} from '../../constants';
import {doRequest} from '../../helpers/network';

const INITIAL_STATE = {
  open_supply: false,
  add_supply_loder: false,
};

const GET_SUPPLY_DATA = 'GET_SUPPLY_DATA';
const GET_SUPPLY_DATA_COMPLETED = 'GET_SUPPLY_DATA_COMPLETED';
const GET_SUPPLY_DATA_FAILED = 'GET_SUPPLY_DATA_FAILED';
const OPEN_ADD_SUPPLY = 'OPEN_ADD_SUPPLY';

export const openAddSupply = (value) => {
  return async (dispatch) => {
    dispatch({type: OPEN_ADD_SUPPLY, payload: value});
  };
};

const DROP_DOWN_DATA = 'DROP_DOWN_DATA';
const DROP_DOWN_DATA_COMPLETED = 'DROP_DOWN_DATA_COMPLETED';
const DROP_DOWN_DATA_FAILED = 'DROP_DOWN_DATA_FAILED';

const ADD_SUPPLY_RANGE = 'ADD_SUPPLY_RANGE';
const ADD_SUPPLY_RANGE_COMPLETED = 'ADD_SUPPLY_RANGE_COMPLETED';
const ADD_SUPPLY_RANGE_FAILED = 'ADD_SUPPLY_RANGE_FAILED';

const GET_SUPPLY_RANGE = 'GET_SUPPLY_RANGE';
const GET_SUPPLY_RANGE_COMPLETED = 'GET_SUPPLY_RANGE_COMPLETED';
const GET_SUPPLY_RANGE_FAILED = 'GET_SUPPLY_RANGE_FAILED';

const UPDATE_SUPPLY_RANGE = 'UPDATE_SUPPLY_RANGE';
export const getSupplyRangePrice = (value, edit = false, extraValue = {}) => {
  return async (dispatch) => {
    let response = {};

    dispatch({type: GET_SUPPLY_RANGE});

    let update = edit ? 'PUT' : 'GET';

    try {
      response = await doRequest({
        method: REQUEST_TYPE[update],
        url: 'customer/customer-supply-price-range/' + value,
        data: extraValue,
      });
      const {data, status} = response || {};
      if (status) {
        if (edit) {
          dispatch({
            type: UPDATE_SUPPLY_RANGE,
            payload: data,
          });
        } else {
          dispatch({
            type: GET_SUPPLY_RANGE_COMPLETED,
            payload: data,
          });
        }
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: GET_SUPPLY_RANGE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_SUPPLY_RANGE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addSupplyRangePrice = (value) => {
  return async (dispatch) => {
    let response = {};

    dispatch({type: ADD_SUPPLY_RANGE});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: 'customer/customer-supply-price-range/',
        data: value,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: ADD_SUPPLY_RANGE_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ADD_SUPPLY_RANGE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_SUPPLY_RANGE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const supplyPriceRangeDropdown = (value, id = '') => {
  return async (dispatch) => {
    let response = {};

    dispatch({type: DROP_DOWN_DATA});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: 'customer/customer-supply-price-range/master-data/' + id,
      });
      const {data, status} = response || {};
      if (status) {
        if (id) {
          dispatch({
            type: 'UPDATE_DROP_DOWN_DATA',
            payload: data,
          });
        } else {
          dispatch({
            type: DROP_DOWN_DATA_COMPLETED,
            payload: {...data, customers_any: value},
          });
        }
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: DROP_DOWN_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DROP_DOWN_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// export const getSupplyData = () => {
//   return async (dispatch) => {
//     let response = {};
//     dispatch({type: GET_SUPPLY_DATA});
//     try {
//       response = await doRequest({
//         method: REQUEST_TYPE.GET,
//         url: 'finance/partner-dispute/',
//       });
//       const {data, status} = response || {};
//       if (status) {
//         const {partner_dispute_ids, partner_disputes} = data;
//         dispatch({type: GET_SUPPLY_DATA_COMPLETED, payload: data});
//       } else {
//         const {message = 'Error'} = response || {};
//         dispatch({
//           type: GET_SUPPLY_DATA_FAILED,
//           payload: {error: message},
//         });
//       }
//     } catch (err) {
//       dispatch({
//         type: GET_SUPPLY_DATA_FAILED,
//         payload: {error: err.message},
//       });
//     }
//     return response;
//   };
// };

// reducers
export default (state = INITIAL_STATE, action) => {
  console.log('ACTIONS', action);
  const {type, payload} = action || {};
  switch (type) {
    case UPDATE_SUPPLY_RANGE:
      let supplyPriceRange = state.supply_range_data.all_supply_ranges.map(
        (item) => (item.id === payload.id ? payload : item),
      );

      return {
        ...state,
        supply_price_table_loader: false,
        supply_range_data: {
          ...state.supply_range_data,
          all_supply_ranges: supplyPriceRange,
        },
      };

    case GET_SUPPLY_RANGE:
      return {
        ...state,
        supply_price_table_loader: true,
      };

    case GET_SUPPLY_RANGE_COMPLETED:
      return {
        ...state,
        supply_price_table_loader: false,
        supply_range_data: payload,
      };

    case GET_SUPPLY_RANGE_FAILED:
      return {
        ...state,
        supply_price_table_loader: false,
      };
    case ADD_SUPPLY_RANGE:
      return {
        ...state,
        add_supply_loder: true,
      };

    case ADD_SUPPLY_RANGE_COMPLETED:
      let updateData = {
        ...state,
        add_supply_loder: false,
        open_supply: false,
        supply_range_data: {
          ...state.supply_range_data,
          all_supply_ranges: [
            payload,
            ...state.supply_range_data.all_supply_ranges,
          ],
        },
      };
      return {
        ...updateData,
      };

    case ADD_SUPPLY_RANGE_FAILED:
      return {
        ...state,
        add_supply_loder: false,
      };

    case DROP_DOWN_DATA_COMPLETED:
      return {
        ...state,
        dropdown_data: payload,
      };

    case 'UPDATE_DROP_DOWN_DATA':
      return {
        ...state,
        dropdown_data: {
          ...state.dropdown_data,
          ...payload,
          loading_warehouse: Object.values(payload.loading_warehouse_data),
          unloading_warehouse: Object.values(payload.unloading_warehouse_data),
        },
      };

    case GET_SUPPLY_DATA:
      return {
        ...state,
        supply_table_loading: true,
        supply_table_error: '',
      };

    case GET_SUPPLY_DATA_COMPLETED:
      return {
        ...state,
        supply_table_loading: false,
        supply_table_data: payload,
        supply_table_error: '',
      };
    case GET_SUPPLY_DATA_COMPLETED:
      return {
        ...state,
        supply_table_loading: false,
        supply_table_data: payload,
        supply_table_error: '',
      };

    case GET_SUPPLY_DATA_FAILED:
      return {
        ...state,
        supply_table_loading: false,
        supply_table_error: payload.error,
      };

    case OPEN_ADD_SUPPLY:
      return {
        ...state,
        open_supply: payload.open_supply,
      };

    default:
      return {
        ...state,
      };
  }
};
